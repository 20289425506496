import { Controller } from "@hotwired/stimulus"
import intlTelInput, { Iti } from "intl-tel-input"

// Connects to data-controller="phone-input"
export default class extends Controller {
  static targets = [ "field" ]
  declare private readonly fieldTarget: HTMLInputElement
  declare private iti: Iti

  connect() {
    this.iti = intlTelInput(this.fieldTarget, {
      strictMode: true,
      utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/21.2.5/js/utils.min.js",
      nationalMode: false,
      initialCountry: "al",
      preferredCountries: []
    })
  }

  disconnect() {
    this.iti.destroy()
  }
}
