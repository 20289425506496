import { Controller } from "@hotwired/stimulus"
import SignaturePad, { PointGroup } from "signature_pad"

// Connects to data-controller="signature-pad"
export default class extends Controller {
  static readonly targets = [ "canvas", "dataInput", "base64Input" ]

  declare readonly canvasTarget: HTMLCanvasElement
  declare readonly dataInputTarget: HTMLInputElement
  declare readonly base64InputTarget: HTMLInputElement

  declare private signaturePad: SignaturePad

  connect() {
    this.signaturePad = new SignaturePad(this.canvasTarget)
    this.signaturePad.addEventListener("endStroke", this.save.bind(this))

    this.resizeCanvas(this.canvasTarget)
    this.load()

    window.addEventListener("resize", () => {
      this.resizeCanvas(this.canvasTarget)
      this.load()
    })
  }

  load() {
    if (this.dataInputTarget.value) {
      this.signaturePad.fromData(JSON.parse(this.dataInputTarget.value) as PointGroup[])
    }
  }

  save() {
    this.dataInputTarget.value   = JSON.stringify(this.signaturePad.toData())
    this.base64InputTarget.value = this.signaturePad.toDataURL()
  }

  clear(event: MouseEvent) {
    this.signaturePad.clear()
    event.preventDefault()
  }

  private resizeCanvas(canvas: HTMLCanvasElement) {
    const ratio =  Math.max(window.devicePixelRatio || 1, 1)

    canvas.style.width  = `${canvas.offsetWidth}px`
    canvas.style.height = `${canvas.offsetHeight}px`
    canvas.width        = canvas.offsetWidth * ratio
    canvas.height       = canvas.offsetHeight * ratio
    canvas.getContext("2d")?.scale(ratio, ratio)

    this.signaturePad.clear()
  }
}
