import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clipboard"
export default class extends Controller {
  static targets = [ "source" ]
  declare readonly sourceTarget: HTMLElement

  copy(event: MouseEvent) {
    event.preventDefault()
  }
}
