import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr"

// Connects to data-controller="date-picker"
export default class extends Controller {
  declare private picker: flatpickr.Instance

  connect() {
    this.picker = flatpickr(this.element, { allowInput: true, monthSelectorType: "static" })
  }

  disconnect() {
    this.picker.destroy()
  }
}
