import { Application } from "@hotwired/stimulus"

const application = Application.start()

// Configure Stimulus development experience
declare global {
  interface Window {
    Stimulus: Application
  }
}

application.debug = false
window.Stimulus   = application

export { application }
